<template>
  <el-row class="row" type="flex" justify="center">
    <el-col class="form-content">
      <el-form
        v-if="!loading"
        v-loading="loading"
        size="middle"
        class="teacher-form"
        :model="formData"
        ref="postForm"
        label-width="100px"
        label-position="right"
        :rules="rules"
        :disabled="isView"
      >
        <!-- <el-row class="sectionLine">
          <div class="inline divSection"></div>
          <div class="inline sectionTitle">基本信息</div>
        </el-row> -->
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item prop="Name" label="名字">
              <el-input
                v-model="formData.Name"
                placeholder="请输入名字"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item prop="Name" label="名字">
              <PictureSelector
                :uploadPath="'/File/UploadSingleFile'"
                @input="fileImg"
                :width="240"
                :height="320"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="简介" prop="Description">
              <div v-if="isView" v-html="formData.Description"></div>
              <rich-text
                v-else
                :value="formData.Description"
                name="Description"
                @input="updateForm"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="发布时间" prop="ReleaseTime">
              <div v-if="isView">{{ formData.PublishTime }}</div>
              <el-date-picker
                v-else
                v-model="formData.PublishTime"
                @change="datechange"
                type="datetime"
                format="yyyy-MM-dd HH:mm"
                :style="{ width: '100%' }"
                placeholder="请输入发布时间"
                clearable
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <el-col class="mb-40 ml-100" :span="24">
        <el-button
          v-if="!isView"
          size="large"
          type="primary"
          @click="submitForm('postForm')"
          >确定</el-button
        >
        <el-button size="large" @click="cancle">{{
          isView ? "返回" : "取消"
        }}</el-button>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import { formartDate } from "@/utils/tools";
import PictureSelector from "@/components/PictureSelector";
import RichText from "@/components/base/RichText";
import presentTime from "@/mixins/content-tools";
import store from "@/store";
import {
  getInfo,
  updateEntity,
  createEntity
} from "@/api/enterprise/productionService";
export default {
  mixins: [presentTime],
  components: {
    RichText,
    PictureSelector
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    isView: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ""
    },
    orgId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        Avatar: "",
        Name: "",
        EnterpriseId: "",
        Description: "",
        PublishTime: ""
      },
      EnterpriseList: [],
      rules: {
        Name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        EnterpriseId: [{ required: true, message: "请选择", trigger: "blur" }],
        Description: [
          { required: true, message: "请输入文本", trigger: "blur" }
        ]
      }
    };
  },
  created() {},
  mounted() {
    this.initialize();
  },
  methods: {
    updateForm(fieldName, value) {
      console.log("修改参数:", fieldName + "值：", value);
      this.formData[fieldName] = value;
    },
    async initialize() {
      this.loading = true;
      try {
        // 新增和编辑时需要获取下拉配置
        // 查看和编辑时需要获取详情
        if (this.isView || this.isEdit) {
          const res = await getInfo({ id: this.id });
          console.log(6635, res);
          if (res.IsSuccess) {
            this.formData = Object.assign({}, this.formData, res.Result);
          }
        } else {
          this.formData.PublishTime = this.presentTime();
          this.formData.EnterpriseId = store.getters.employee[0].EmployerId;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.isUploading = true;
          let isSuccess = false;
          let message = "服务器错误！";
          const postData = Object.assign({}, this.formData);
          try {
            if (this.isEdit) {
              const res = await updateEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            } else {
              const res = await createEntity(postData);
              isSuccess = res.IsSuccess;
              message = res.Message;
            }
            if (isSuccess) {
              this.$message.success(message || "保存成功!");
              this.$router.go(-1);
            } else {
              this.$message.error(message || "保存失败!");
            }
          } catch (error) {
            console.log(error);
          } finally {
            this.isUploading = false;
          }
        } else {
          this.$message({
            type: "error",
            message: "表单填写错误"
          });
          return;
        }
      });
    },
    cancle() {
      if (!this.isView) {
        this.$confirm(
          "系统将不会保存您所更改的内容！",
          "您确定要离开当前页面吗？",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }
        )
          .then(() => {
            this.$router.go(-1);
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        this.$router.go(-1);
      }
    },
    datechange(val) {
      this.formData.PublishTime = formartDate(val, "all");
    },
    // 上传专家照片
    fileImg(name, path) {
      console.log(path);
      this.formData.Avatar = path;
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  flex: 1;
  box-sizing: border-box;
  max-height: calc(100vh - 320px);
  overflow: auto;
}
.form-content {
  width: 900px;
}
.teacher-form {
  display: flex;
  flex-direction: column;
  padding: 40px 0 0;
  ::v-deep {
    .el-form-item {
      line-height: 40px;
    }
  }
}
.sectionLine {
  display: flex;
  align-items: center;
  margin-bottom: 32px;
}
.divSection {
  width: 4px;
  height: 16px;
  background: #3d5afe;
  border-radius: 2px;
  margin-right: 4px;
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 22px;
}
</style>
